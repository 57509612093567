import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import Cookies from 'universal-cookie';
import CssBaseline from '@mui/material/CssBaseline';
import { ToastContainer, toast, Flip } from 'react-toastify';
import { Loader, Text } from 'kolkit';
import Appsignal from '@appsignal/javascript';

import { randomFromArray } from 'utils';
import { setClientToken } from 'actions/env';
import { getUserParams, logout } from 'actions/user';
import { conf, env, ENV_DEV } from 'config/env';
import { useDispatch, useSelector } from 'utils/redux';
import loadingSentences from 'locales/loadingSentences';

import GlobalErrorBoundary from './GlobalErrorBoundary';
import Structure from './Structure';
import ProfileDrawer from './profileDetails/Drawer';
import PageNavigatorChecker from './dispatchers/PageNavigatorChecker';
import OutdatedBrowserAlert from './ui/OutdatedBrowserAlert';
import WarningNoInternet from './ui/WarningNoInternet';
import ToastCloseButton from './ui/ToastCloseButton';
import NewVersionRequired from './ui/NewVersionRequired';
import MaintenanceMode from './ui/MaintenanceMode';
import ForbiddenActionAlert from './ui/ForbiddenActionAlert';

import packageInfo from '../../package.json';

import 'css/scss/selections/selection.scss';
import 'react-toastify/dist/ReactToastify.min.css';
import '@brandandcelebrities/viz/dist/css/viz.css';

export const appsignal = new Appsignal({
  key: conf.appsignal,
  revision: packageInfo?.version,
  namespace: 'Influence',
});

const selector = ({ env, ui, user }) => ({
  locale: env.locale,
	hasApiError: ui?.modales?.apiError?.show || false,
  hasInternet: env?.hasInternet,
  enableToasts:
    !ui?.modales?.apiError?.show &&
    env?.hasInternet &&
    !env?.newVersionRequired &&
    !env?.maintenanceModeActivated &&
    !env?.forbiddenActionAlert,
  user: user?.profile,
});

const MainContainer = () => {
  const [mounted, setMounted] = useState(false);

  const dispatch = useDispatch();
  const { locale, hasInternet, enableToasts, user } = useSelector(selector);

  useEffect(() => {
    // Getting Token from Cookies yeah
    const cookies = new Cookies();
    let cookieToken = cookies.get(conf.cookieToken);

    if (!cookieToken) {
      // No cookie set => redirection to login page
      if (!conf.overrideEnv && env !== ENV_DEV) void dispatch(logout());
      else {
        cookieToken = process.env.REACT_APP_COOKIE_TOKEN || 'dev-hashstrings';
      }
    }

    dispatch(setClientToken(cookieToken));

    void dispatch(getUserParams()).then((res) => {
      if (res) setMounted(true);
    });

    return () => {
      setMounted(false);
    };
  }, [dispatch]);

  const renderEnvModales = useMemo(() => {
    return (
      <>
        {!hasInternet && <WarningNoInternet />}
        <NewVersionRequired />
        <MaintenanceMode />
        <ForbiddenActionAlert />
      </>
    );
  }, [hasInternet]);

  const renderGlobalLoading = useCallback(() => {
    const welcomeSentence = randomFromArray(loadingSentences[locale]);

    return (
      <div className="global-loader">
        <div className="loader">
          <div>
            <h1>
              <img
                src="/logo/logo-horizontal-navy.svg"
                className="logo"
                alt="Kolsquare"
              />
            </h1>
          </div>
          <Loader className="global-loader_loader" padding={16} />
          <Text tag="h5" fontTitle>
            {welcomeSentence}
          </Text>
        </div>
      </div>
    );
  }, [locale]);

  const renderApp = useMemo(() => {
    if (!mounted) return renderGlobalLoading();

    return (
      <>
        <CssBaseline />
        <GlobalErrorBoundary user={user} dispatch={dispatch}>
          <Structure />
        </GlobalErrorBoundary>

        {/* Checkers */}
        <PageNavigatorChecker />

        <ProfileDrawer />
        <OutdatedBrowserAlert />
      </>
    );
  }, [mounted, renderGlobalLoading, user, dispatch]);

  return (
    <>
      {renderApp}
      {/* ERROR AND WARNINGS */}
      {renderEnvModales}
      {enableToasts && (
        <ToastContainer
          enableMultiContainer
          transition={Flip}
          autoClose={2000}
          closeButton={<ToastCloseButton />}
          toastClassName="toast"
          position={toast.POSITION.BOTTOM_CENTER}
          closeOnClick={false}
          pauseOnHover
          draggablePercent={40}
        />
      )}
    </>
  );
};

export default memo(MainContainer);
