import React, { useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl-phraseapp';

import { useSelector } from 'utils/redux';
import { Modal2, Text } from 'kolkit';

const ApiErrorModale = ({ isRuntimeError = false }) => {
  const intl = useIntl();
  const code: string | null = useSelector(
    ({ ui }) => (ui.modales.apiError.data?.code as string) || null,
  );

  const action = useMemo(
    () => ({
      primary: {
        title: intl.formatMessage({ id: 'global.cta.reloadApp' }),
        onClick: () => {
          window.location.reload();
        },
      },
    }),
    [intl],
  );

  return (
    <Modal2
      on
      isAlert
      title={intl.formatMessage(
        { id: code ? 'errorModale.titleKnown' : 'errorModale.titleUnknown' },
        { code },
      )}
      action={action}
      onClick={() => {
        return null;
      }}
    >
      <Text>
        <FormattedMessage
          id={
            code === 'runtime' || isRuntimeError
              ? 'errorModale.runtime'
              : 'errorModale.critical'
          }
        />
      </Text>
    </Modal2>
  );
};

export default ApiErrorModale;
