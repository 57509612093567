import { createAction } from 'redux-actions';
import _get from 'lodash.get';
import _isNil from 'lodash.isnil';
import _isEqual from 'lodash.isequal';
import _groupBy from 'lodash.groupby';
import Cookies from 'universal-cookie';
import { nanoid } from 'nanoid';

import { conf } from 'config/env';
import currencies from 'config/currencies';

import { planhatTraker } from 'utils/tags';
import toast from 'utils/toast';
import { celebrityNameFormatter } from 'utils';
import errorsManager from 'utils/errorsManager';
import { getIntl } from 'utils/HOCs/IntlGlobalSingleton';

import { setClientToken } from 'actions/env';
import * as uiActions from 'actions/ui';
import * as profilesActions from 'actions/profiles';
import * as actionsViews from 'actions/views';
import { getUserProjects, userParamsLoaded } from 'actions/user';
import * as actionMessaging from 'actions/messaging';
import { updateFolderAction } from 'actions/campaignsFolders';
import { setControlledLoader } from 'actions/ui'
import {
  getCampaignWorkflows,
  updateCurrentWorkflowPropositionCount,
  updateProfilesDeletedCount,
} from 'slices/campaignWorkflow.slice';

import * as CAMPAIGNS from 'constants/campaigns';
import {
  USER_ROLES,
  STATUSES_PHRASE_NO_STATUS_KEY
} from 'constants/states';

import * as api from 'api/campaigns';
import { getTrackingLinksForCampaign } from 'api/trackingLinks';

import * as mapper from 'mappers/campaigns';
import { mapProfiles } from 'mappers/profiles';
import routes from 'config/routes';

import { isPublicReportingPage } from 'validators/views';

import { resetCampaignDetails, getProfilesIdsSorted } from './campaignDetails';


const remove = createAction(CAMPAIGNS.REMOVE);
const add = createAction(CAMPAIGNS.ADD);

export const update = createAction(CAMPAIGNS.UPDATE);
export const batch = createAction(CAMPAIGNS.BATCH);
export const batchSet = createAction(CAMPAIGNS.BATCH_SET);

export const addProfiles = createAction(CAMPAIGNS.ADD_PROFILES);
export const resetProfiles = createAction(CAMPAIGNS.RESET_PROFILES);
export const pushProfiles = createAction(CAMPAIGNS.PUSH_PROFILES);
export const updateProfiles = createAction(CAMPAIGNS.UPDATE_PROFILES);
export const updateProfile = createAction(CAMPAIGNS.UPDATE_PROFILE);
export const removeProfiles = createAction(CAMPAIGNS.REMOVE_PROFILES);

export const increaseCampaignCommentCount = createAction(CAMPAIGNS.INCREASE_COMMENT_COUNT);

const timeout = ms => new Promise(resolve => setTimeout(resolve, ms));


export const removeCampaign = id => async (dispatch, getState) => {
  const {
    campaigns,
    campaignsFolders,
  } = getState();

  if (!id) return null;

  const campaign = campaigns[id];

  await api.removeCampaign(id);
  dispatch(remove(id));
  resetCampaignDetails(dispatch);

  // remove campaign in campaignsFolders store
  const folderId = campaign?.core?.folderId;
  if (folderId) {
    const oldFolderData = campaignsFolders?.[folderId];
    const oldFolderDataUpdated = {
      ...oldFolderData,
      project_ids: oldFolderData.project_ids?.filter((campaignId) => Number(id) !== Number(campaignId)),
    };
    dispatch(updateFolderAction(oldFolderDataUpdated));
  }

  await planhatTraker({
    action: 'delete_campaign',
  })

  const intl = getIntl();

  toast(
    intl.formatMessage(
      { id: "toasts.campaignDeleted" },
      { name: campaign?.core?.name?.trim() }
    ),
    { type: 'success'}
  );
}

export const updateCampaignsState = ({ ids, state }) => async (dispatch, getState) => {
  dispatch(setControlledLoader({ id: 'updateCampaignsState', show: true }));

  const { campaigns } = getState();

  await api.updateCampaignsStatus({
    ids,
    newState: state
  });

  ids?.forEach(id => {
    // Set updateAt to fix sorter.
    dispatch(update({
      id,
      core: {
        state,
        updatedAt: new Date().toISOString(),
      }
    }));
  });

  const intl = getIntl();

  if (ids?.length === 1) {
    const campaign = campaigns[ids[0]];
    toast(
      intl.formatMessage(
        { id: "toasts.stateCampaignUpdated" },
        {
          name: campaign?.core?.name?.trim(),
          newState: intl.formatMessage({ id: `campaigns.states.${state}`})
        },
      ),
      { type: 'success'}
    );
  } else {
    toast(
      intl.formatMessage(
        { id: "toasts.stateCampaignsUpdated" },
        {
          count: ids?.length,
          newState: intl.formatMessage({ id: `campaigns.states.${state}`})
        },
      ),
      { type: 'success'}
    );
  }
  dispatch(setControlledLoader({ id: 'updateCampaignsState', show: false }));
}

export const addProfilesToCampaigns = ({
  from,
  campaignIds,
  profileIds,
  noToast,
}) => async (dispatch, getState) => {
  if (!profileIds?.length) return true;

  dispatch(setControlledLoader({ id: 'globalLoader', show: true }));

  if (!campaignIds || !campaignIds?.length) {
    // No campaign selected, we have to create a new one
    const res = await dispatch(uiActions.promptCreateNewCampaign({ from }));
    if (res) {
      dispatch(addProfilesToCampaigns({
        from,
        campaignIds: [res.id],
        profileIds,
      }));
    }
    return res;
  }

  const {
    profiles,
    campaigns,
  } = getState();

  await api.addProfilesToCampaigns({ campaignIds, profileIds });

  campaignIds.map(campaignId => {
    const campaign = campaigns[campaignId];
    // Adding profiles to store campaign
    dispatch(addProfiles({
      campaignId,
      profileIds,
      profilesCount: _get(campaign,
        'core.profilesCount',
        0
      ) + profileIds?.length,
    }));
    return true;
  })

  await planhatTraker({
    action: 'add_profile_to_campaign',
  })

  if (!noToast) {
    const intl = getIntl();
    toast(
      intl.formatMessage(
        { id: campaignIds?.length === 1 ? "toasts.profilesAddedToCampaign" : "toasts.profilesAddedToCampaigns"},
        {
          count: profileIds?.length,
          name: celebrityNameFormatter(profiles?.[profileIds[0]]?.core)?.trim(),
          campaigns: campaignIds?.length,
        },
      ),
      { type: 'success'}
    );
  }

  dispatch(setControlledLoader({ id: 'globalLoader', show: false }));

  return true;
};

export const removeProfilesFromCampaigns = ({ campaignIds, profileIds }) => async (dispatch, getState) => {
  const { profiles, campaigns } = getState();

  if (!campaignIds.length || !profileIds?.length) return true;

  const isPaneProfiles = window.location.href.includes('projects');

  await api.removeProfilesToCampaigns({ campaignIds, profileIds });
  await planhatTraker({
    action: 'remove_profile_from_campaign',
  })

  campaignIds.map(campaignId => {
    const campaign = campaigns[campaignId];
    // Removing from store campaign
    dispatch(removeProfiles({
      campaignId,
      profileIds,
      profilesCount: _get(campaign,
        'core.profilesCount',
        0
      ) - profileIds?.length,
    }));

    if (isPaneProfiles) {
      // Update profiles count per status
      const profilesDeleted = profileIds.map(id => ({ id, status: campaign?.profiles?.[id]?.state}));
      const profilesByStatus = _groupBy(profilesDeleted, 'status');
      dispatch(updateProfilesDeletedCount({
        deleteCount: profilesDeleted?.length,
        deletedByStatus: Object.entries(profilesByStatus || {})?.reduce((acc, [key, value]) => ({
          ...acc,
          [key]: value.length,
        }), {}),
      }));

      dispatch(getCampaignProfiles({
        campaignId,
        scopes: 'core,tags,snas,followers_insights,content_requests',
        filters: {
          page: 0,
        }
      }))
    }
    return true;
  })

  // Removing from selection on both tabs
  dispatch(actionsViews.removeProfilesFromSelection(profileIds, 'profiles'));
  dispatch(actionsViews.removeProfilesFromSelection(profileIds, 'compare'));
  dispatch(actionsViews.removeProfilesFromSelection(profileIds, 'shopify'));

  const intl = getIntl();

  toast(
    intl.formatMessage(
      { id: campaignIds?.length === 1 ? "toasts.profilesRemovedFromCampaign" : "toasts.profilesRemovedFromCampaigns"},
      {
        count: profileIds?.length,
        profileName: celebrityNameFormatter(profiles?.[profileIds[0]]?.core)?.trim(),
        campaigns: campaignIds?.length,
      },
    ),
    { type: 'success'}
  );

  return true;
}

export const createNewCampaign = query => async (dispatch, getState) => {
  const { user: { profile } } = getState();
  const intl = getIntl();

  const res = await api.createNewCampaign(query);
  await planhatTraker({
    action: 'create_campaign',
  })

  const { id } = mapper.createNewCampaign.fromApi(res);

  dispatch(add({
    id,
    core: {
      ...query,
      state: query.state || 'draft',
      role: USER_ROLES.OWNER,
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
      isMine: true,
      isWatching: false,
      active: true,
      teamId: profile?.teamId,
      folderId: null,
    },
    admin: {
      accountExecutive: query.accountExecutive || null,
      client: query.client || null,
      clientFinal: query.clientFinal || null,
    },
    affilae: {
      campaignId: query.affilaeCampaignId
    }
  }));

  toast(
    intl.formatMessage(
      { id: 'campaign.toast.create.success' },
      { name: query.name },
    ),
    {
      link: {
        to: `/${routes.campaigns}/${id}`,
        label: intl.formatMessage({ id: 'campaigns.cta.seeCampaign' }),
      },
      duration: 5000,
      type: 'success',
    },
  );

  return {
    ...query,
    id,
  };
}

export const updateCampaign = ({id, data}) => async (dispatch, getState) => {
  if (!data) return false;

  await api.updateCampaign({ campaignId: id, ...data });

  const { campaigns } = getState();

  const { affilaeCampaignId, ...coreCampaignData } = data;

  dispatch(update({
    id,
    core: {
      ...coreCampaignData,
      updatedAt: new Date().toISOString(),
    },
    affilae: {
      campaignId: affilaeCampaignId
    }
  }));

  const intl = getIntl();
  const campaign = campaigns[id];

  toast(
    intl.formatMessage(
      { id: "toasts.campaignUpdated"},
      { name: campaign?.core?.name?.trim() },
    ),
    { type: 'success'}
  );

  return true;
}

export const duplicateCampaign = campaignId => async dispatch => {
  const intl = getIntl();

  dispatch(setControlledLoader({ id: 'paneProfiles', show: true }));
  await api.duplicateCampaign(campaignId);
  dispatch(getUserProjects());
  toast(
    intl.formatMessage({ id: 'campaigns.clone.success' }),
    { type: 'success' },
  )
  dispatch(setControlledLoader({ id: 'paneProfiles', show: false }));
}

export const updateCampaignWatchingStatus = ({ id, isWatching }) => async (dispatch, getState) => {
  await api.updateCampaignWatchingStatus({campaignId: id, isWatching});

  dispatch(update({
    id,
    core: {
      isWatching,
      updatedAt: new Date().toISOString(),
    }
  }));

  const { campaigns } = getState();
  const campaign = campaigns[id];
  const intl = getIntl();
  toast(
    intl.formatMessage(
      { id: isWatching ? "toasts.campaignNotificationEnabled" : "toasts.campaignNotificationDisabled" },
      { campaignName: campaign?.core?.name?.trim()}
    ),
    { type: 'success' },
  );
}

/* CAMPAIGN ACTIONS */
export const getCampaignDetails = id => async (dispatch, getState) => {
  const {
    profile: { admin: isAdmin },
  } = getState().user;

  const oldData = getState().campaigns?.[id] ?? {}

  dispatch(actionsViews.update({
    view: 'campaignDetails',
    loading: true,
    paramsLoaded: false,
  }));

  const details = await api.getCampaignDetails({ campaignId: id });

  if (!details || details.error) return details;

  const { castingPptxIsLoading, ...datas } = mapper.getCampaignDetails.fromApi(details);

  dispatch(actionMessaging.getFrom('campaign'));
  dispatch(actionMessaging.reloadMessages(true));

  // Update store campaign
  await dispatch(update({
    id,
    ...datas,
    core: {
      ...oldData.core,
      ...datas.core,
      role: isAdmin ? USER_ROLES.ADMINBC : datas.core.role,
      updatedAt: oldData?.core?.updatedAt, // Remove this if updated_at attribute is added on getCampaignDetails api
    }
  }));

  await dispatch(actionsViews.updateKey({
    view: 'campaignDetails',
    key: "profiles",
    castingPptxIsLoading
  }));

  await dispatch(actionsViews.update({
    view: 'campaignDetails',
    id,
    loading: false,
    paramsLoaded: true
  }));

  return true;
};

export const getCampaignProfiles = ({
  campaignId, search = '', filters = {}, scopes = null
}) => async (dispatch, getState) => {
  const {
    views: {
      campaignDetails: {
        profiles: { params, filteredProfiles },
      },
    },
  } = getState();

  if (!campaignId) {
    return null;
  }

  dispatch(actionsViews.update({
    view: 'campaignDetails',
    loading: true,
  }));

  if (filters?.page === 0) {
    dispatch(actionsViews.updateKey({
      view: 'campaignDetails',
      key: 'profiles',
      params: {
        id: nanoid()
      }
    }))
  }

  try {
    const data = await api.getCampaignProfiles({
      campaignId,
      params: {
        scopes: !scopes ? 'core,tags,snas,followers_insights,content_requests' : scopes,
        page: params.page,
        per_page: params.per_page,
        search: filters?.page === 0 ? search : params.search,
        stats_sharing: params.stats_sharing,
        with_contactable_email: params.with_contactable_email,
        tags: params.tags,
        status: params.status,
        order_by: params.order_by,
        order_direction: params.order_direction,
        ...filters
      }
    });

    if (!data || data.error) {
      return data;
    }

    const isEqualProfileIds = _isEqual(data.profile_ids, filteredProfiles);

    dispatch(
      actionsViews.updateKey({
        view: 'campaignDetails',
        key: 'profiles',
        params: {
          ...filters,
          search: filters?.page === 0 ? search : params.search,
          total: data.total_count,
          load_more:
            data.page + 1 < Math.ceil(data.total_count / data.per_page),
          page: data.page + 1,
        },
        ...(!isEqualProfileIds ? { filteredProfiles: data.profile_ids } : {}),
      }),
    );

    const profiles = mapProfiles.fromApi(data?.profiles);
    dispatch(profilesActions.batch(profiles));

    const campaignProfiles = mapper.mapProfiles.fromApi(data?.profiles);

    dispatch(pushProfiles({
      page: data.page,
      campaignId,
      profiles: campaignProfiles.reduce((acc, p) => ({
        ...acc,
        [p.id]: p,
      }), {}),
    }));

    dispatch(actionsViews.update({
      view: 'campaignDetails',
      loading: false,
    }));
  } catch (error) {
    console.error('getCampaignProfiles:', error);
    dispatch(actionsViews.update({
      view: 'campaignDetails',
      loading: false,
    }));
  }
};


export const getCampaignWorkflowProfiles = (campaignIdFromUrl) =>
  async (dispatch, getState) => {
    const {
      views: {
        campaignDetails: {
          id,
          profiles: { params, filteredProfiles },
        },
      },
    } = getState();

    const campaignId = campaignIdFromUrl || id;
    if (!campaignId) {
      return null;
    }

    dispatch(
      actionsViews.update({
        view: 'campaignDetails',
        loading: true,
      }),
    );

    if (params?.page === 0) {
      dispatch(
        actionsViews.updateKey({
          view: 'campaignDetails',
          key: 'profiles',
          params: {
            id: nanoid(),
          },
        }),
      );
    }

    try {
      const data = await api.getCampaignProfiles({
        campaignId,
        params: {
          ...params,
          scopes:
            !params?.scopes
              ? 'core,tags,snas,followers_insights,content_requests'
              : params?.scopes,
        },
      });

      if (!data || data.error) {
        return data;
      }

      const isEqualProfileIds = _isEqual(data.profile_ids, filteredProfiles);

      dispatch(
        actionsViews.updateKey({
          view: 'campaignDetails',
          key: 'profiles',
          params: {
            ...params,
            total: data.total_count,
            load_more:
              data.page + 1 < Math.ceil(data.total_count / data.per_page),
          },
          ...(!isEqualProfileIds ? { filteredProfiles: data.profile_ids } : {}),
        }),
      );

      const profiles = mapProfiles.fromApi(data?.profiles);
      dispatch(profilesActions.batch(profiles));

      const campaignProfiles = mapper.mapProfiles.fromApi(data?.profiles);

      dispatch(
        updateProfiles({
          id: campaignId,
          profiles: campaignProfiles,
        }),
      );

      dispatch(
        actionsViews.update({
          view: 'campaignDetails',
          loading: false,
        }),
      );
    } catch (error) {
      console.error('getCampaignProfiles:', error);
      dispatch(
        actionsViews.update({
          view: 'campaignDetails',
          loading: false,
        }),
      );
    }
  };

export const getPublicCampaignDetails = () => async (dispatch, getState) => {
  const { env: { userToken }} = getState();

  const res = await api.getPublicCampaignDetails(userToken);

  if (!res || res.error) return res

  // Update store profiles
  const profiles = mapProfiles.fromApi(res.profiles);
  dispatch(profilesActions.batch(profiles));

  // Update store campaign
  const campaign = mapper.getCampaignDetails.fromApi(res);
  dispatch(update({
    id: campaign.id,
    ...campaign,
    core: {
      ...campaign.core,
      role: null,
    }
  }));

  const { default: moment } = await import('moment-timezone');

  // Update store user
  dispatch(userParamsLoaded({
    locale: res?.locale,
    timezone: (!res?.timezone || res?.timezone === 'null')
      ? moment.tz.guess(false) // false: ignoreCache
      : res?.timezone,
    dateFormat:res?.date_format,
    currency: {
      ...res?.currency,
      ...currencies.find(curr => curr.value === res?.currency.value),
    },
  }));

  return res;
}

export const getPublicCastingDetails = (token) => async (dispatch, getState) => {
  if (!getState().env.userToken) {
    await dispatch(setClientToken(token));
  }

  const res = await api.getCampaignIdFromToken(token);

  if (!res || res.error) return Promise.reject(res);

  // Get campaign id from response
  const campaignId = res.project_id;
  const castingId = res.id;

  dispatch(actionsViews.update({
    view: 'campaignDetails',
    loading: true,
  }));

  await dispatch(getCampaignDetails(campaignId, { minimal: true }));

  await dispatch(getCampaignWorkflows({ project_id: campaignId }));

  await timeout(500);

  await dispatch(
    getCampaignProfiles({
      campaignId,
      filters: {
        page: 0,
        per_page: 10000, // TODO: add pagination to public casting page
      },
    }),
  );

  return dispatch(actionsViews.update({
    view: 'campaignDetails',
    id: campaignId,
    castingId,
    loading: false
  }));
}

export const updateProfileState = ({
  profileId,
  profilesId,
  campaignId,
  stateId,
  fromConversation,
}) => async (dispatch, getState) => {
  dispatch(setControlledLoader({ id: 'saveStates', show: true }));
  const {
    campaigns,
    profiles,
    messaging: { conversation },
    campaignsWorkflow: { team_statuses },
    views: { campaignDetails },
  } = getState();

  const intl = getIntl();

  const getStatusName = (statusId) => {
    const status = team_statuses[statusId];
    if (!status) return null;
    return status.is_default
      ? intl.formatMessage({ id: `campaigns.statuses.${status.key}` })
      : status.name ||
          intl.formatMessage({ id: STATUSES_PHRASE_NO_STATUS_KEY });
  };

  const newStatus = getStatusName(stateId);
  const campaign = campaigns?.[campaignId];

  // Store number of KOL to remove or add (positive or negative)
  const propositionCountUpdate = [profileId, profilesId].flat().reduce((map, id) => {
    if (!id) return map;
    const state = campaign?.profiles?.[id]?.state;
    if (!state || Number(state) === Number(stateId)) return map;
    return {
      ...map,
      [state]: (map[state] || 0) - 1,
      [stateId]: (map[stateId] || 0) + 1,
    };
  }, {});

  await Promise.all(
    // TODO API: bach update with prifiles ids parameters
    [profileId, profilesId]
      .flat()
      .filter((item) => item)
      .map(async (id) => {
        await api.updateProfileState({
          profileId: id,
          campaignId,
          state: stateId,
        });

        if (campaign?.profiles?.[id]) {
          await dispatch(
            updateProfile({
              id: campaignId,
              profileId: id,
              state: stateId,
              status_name: newStatus,
            }),
          );
        }

        return true;
      }),
  );

  // We are viewing the campaign we are updating
  if (Number(campaignDetails.id) === Number(campaignId)) {
    // Update profiles list
    if (
      campaignDetails.profiles.params.status.length !== 0 && // We are not in the "All" view
      !campaignDetails.profiles.params.status.includes(Number(stateId)) // We are not in filtering the status we are updating the profiles to
    ) {
      // Remove the updated profile from the current view
      await dispatch(
        actionsViews.updateKey({
          view: 'campaignDetails',
          key: 'profiles',
          filteredProfiles: campaignDetails.profiles.filteredProfiles.filter(
            (id) => ![profileId, profilesId].flat().includes(id),
          ),
        }),
      );
    }

    // Update counter in workflow stripe
    dispatch(updateCurrentWorkflowPropositionCount(propositionCountUpdate));
  }

  dispatch(setControlledLoader({ id: 'saveStates', show: false }));

  if (profilesId?.length) {
    toast(
      intl.formatMessage(
        { id: 'campaigns.toast.statuses.profilesUpdated' },
        {
          length: profilesId?.length,
          newStatus,
        },
      ),
      {
        type: 'success',
      },
    );
  }

  if (profileId) {
    const profileName = fromConversation
      ? conversation.profile.name
      : celebrityNameFormatter(profiles[profileId]?.core);

    toast(
      intl.formatMessage(
        { id: 'campaigns.toast.statuses.profileUpdated' },
        {
          name: profileName,
          newStatus,
        },
      ),
      {
        type: 'success',
      },
    );
  }
}


export const updateProfilesDelivery = ({
  profilesIds,
  campaignId,
  delivery,
}) => async (dispatch, getState) => {
  dispatch(setControlledLoader({ id: 'updateDeliveryLoader', show: true }));
  const { profiles, campaigns } = getState();

  const res = await api.updateProfilesDelivery({
    profilesIds,
    campaignId,
    delivery,
  });
  if (res.error) return null;

  const mapped = mapper.updateProfileDelivery.fromApi({ delivery: res });

  const profilesToUpdated = profilesIds.map(id => ({
    ...campaigns?.[campaignId]?.profiles?.[id],
    delivery: {
      ...mapped,
    }
  }))
  dispatch(
    updateProfiles({
      id: campaignId,
      profiles: profilesToUpdated,
    })
  );
  dispatch(setControlledLoader({ id: 'updateDeliveryLoader', show: false }));

  const intl = getIntl();
  const profile = profiles?.[profilesIds?.[0]];
  toast(
    intl.formatMessage(
      { id: profilesIds?.length === 1 ? "campaigns.delivery.update.success" : "campaigns.delivery.updateMany.success"},
      {
        name: celebrityNameFormatter(profile?.core),
        count: profilesIds?.length
      }
    ),
    { type: 'success' },
  );
};

export const getReportingProfileTags = async campaignId => {
  const res = await api.getReportingProfileTags({ campaignId });
  return mapper.mapReportingProfileTags.fromApi(res);
}

export const getReporting = query => async (_, getState) => {
  /* Yeah, all this stuff for debugging :/ */
  const { campaigns, profiles } = getState();
  const campaign = campaigns[query.campaignId];

  const finalQuery = {
    ...query,
    timezone: campaign?.core?.timezone,
    isPublic: isPublicReportingPage(),
  }

  // Calling API
  const res = await api.getReporting(finalQuery);

  return mapper.getReporting.fromApi({ ...res, profiles, kols: campaign.kols });
}

export const getAllTrackingLinks = (campaignId) => async (dispatch, getState) => {
  const {
    views: {
      campaignDetails: {
        reporting: { profilesSorted, filters }
      }
    },
    profiles,
    campaigns,
  } = getState();

  const campaign = campaigns?.[campaignId]?.reporting;


  const { tracking_links, total_row } = await getTrackingLinksForCampaign({
    campaign_id: Number(campaignId),
    params: Object.assign(
      {
        sort: '',
        page: 0,
        per_page: 1000,
        search: '',
        scopes: 'profiles,statistics',
        starts_at: campaign?.startDate,
        ends_at: campaign?.endDate,
      },
      filters?.profiles?.length > 0 && { author_ids: filters?.profiles },
    ),
  });

  const trackingLinksByProfileId = (tracking_links || []).reduce(
    (prev, curr) => ({
      ...prev,
      [curr?.profile?.id]: [
        ...(prev[curr?.profile?.id] || []),
        mapper.putTrackingLinksToProfile.fromApi(curr),
      ],
    }),
    {},
  );

  const { by, order } = profilesSorted?.trackinkLinksTable;

  dispatch(
    actionsViews.updateKey({
      view: 'campaignDetails',
      key: 'reporting',
      trackingLinks: {
        profiles: trackingLinksByProfileId,
        total: mapper.getTrackingLinksTotal.fromApi(total_row),
        override: true,
      },
      profilesSorted: {
        trackinkLinksTable: {
          ...profilesSorted.trackinkLinksTable,
          ids: getProfilesIdsSorted({
            by,
            order,
            profiles,
            profilesStats: trackingLinksByProfileId,
          })
        }
      }
    })
  );

  return true;
}

export const getDiscountCodes = (campaignId) => async (dispatch, getState) => {
  const {
    views: {
      campaignDetails: {
        reporting: { profilesSorted, filters }
      }
    },
    profiles,
    campaigns,
  } = getState();

  const campaign = campaigns?.[campaignId]?.core;

  const { status, discount_codes, total_row, total_count } = await api.getDiscountCodes({
    campaignId: Number(campaignId),
    params: Object.assign(
      {
        sort: '',
        page: 0,
        per_page: 1000,
        search: '',
        scopes: 'profiles,statistics',
        starts_at: campaign.startDate,
        ends_at: campaign.endDate,
      },
      filters?.profiles?.length > 0 && { author_ids: filters?.profiles },
    ),
  });

  errorsManager({
    status,
    intlId: 'error',
    source: 'Discount Codes'
  })

  const discountCodesByprofileId = (discount_codes || []).reduce(
    (prev, curr) => ({
      ...prev,
      [curr?.profile?.id]: [
        ...(prev[curr?.profile?.id] || []),
        mapper.getDiscountCodesProfile.fromApi(curr),
      ],
    }),
    {},
  );

  const { by, order } = profilesSorted?.discountCodesTable;

  dispatch(
    actionsViews.updateKey({
      view: 'campaignDetails',
      key: 'reporting',
      discountCodes: {
        profiles: discountCodesByprofileId,
        total: mapper.getDiscountCodesTotal.fromApi({total_row, total_count}),
        override: true,
      },
      profilesSorted: {
        discountCodesTable: {
          ...profilesSorted.discountCodesTable,
          ids: getProfilesIdsSorted({
            by,
            order,
            profiles,
            profilesStats: discountCodesByprofileId,
          })
        }
      }
    })
  );

  return true;
}

export const exportCampaignProfiles = ({ campaignId, type }) => (dispatch, getState) => {
  const {views, env: { userToken, timezone }, user: { profile }} = getState();
  const { castingPptxIsLoading } = views.campaignDetails.profiles;

  switch (type) {
    case 'csv':
      window.open(`${conf.api}/influence/projects/${campaignId}/profiles/export?token=${userToken}`);
      break;
    case 'pptx': {
      const res = !castingPptxIsLoading
        ? api.exportCastingPptx({campaignId, timezone})
        : {error : 'castingPptxIsLoading'}

      const alertType = () => {
        if (res && res.error) {
          if (res.status === 422 && res.error === 'castingPptxIsLoading') return 'error'
        }
        return 'success'
      }

      dispatch(uiActions.showModal({
        id: 'exportMonitor',
        type,
        alertType: alertType(),
        exportType: 'casting',
        exportPptxLoading: castingPptxIsLoading
      }))

      if (!castingPptxIsLoading && alertType() === 'success') {
        dispatch(actionsViews.updateKey({
          view: 'campaignDetails',
          key: "profiles",
          castingPptxIsLoading : {
            id: profile.id
          },
        }))
      }
    }
      break;
    default:
      break;
  }
}

export const exportReporting = ({
  formatType,
  exportPptxLoading,
  campaignId,
}) => (_, getState) => {
  const { campaigns, views, env } = getState();
  const { reporting } = campaigns[campaignId];
  const { reporting: { filters, profileTags } } = views.campaignDetails;

  const tagsLabels = filters?.tags?.map(tagId => {
    const tag = profileTags?.find( ({ id }) => Number(id) === Number(tagId));
    return tag?.value
});
  const params = {
    campaignId,
    timezone: env.timezone,
    ...reporting,
    ...filters,
    tags: tagsLabels,
  };

  switch (formatType) {
    case 'csv': {
      return api.exportReportingCsv(params);
    }
    case 'pptx': {
      return exportPptxLoading
        ? { error: 'pptxLoading' }
        : api.exportReportingPptx(params);
    }
    case 'custom_pptx': {
      return api.exportCustomReportingPptx(params);
    }
    case 'story':
    case 'post': {
      return api.exportReportingMedias(({
        ...params,
        types: [formatType]
      }));
    }
    default:
      return false;
  }
};

export const addPostsToReporting = query => () => {
  return api.addPostsToReporting(query);
}

export const generateShareToken = () => async (dispatch, getState) => {
  const { campaigns, views: {campaignDetails: {id}} } = getState();
  const campaign = campaigns[id];
  dispatch(setControlledLoader({ id: 'generateShareToken', show: true }));
  const res = await api.generateShareToken(id);
  dispatch(setControlledLoader({ id: 'generateShareToken', show: false }));
  if (res.error) return null;

  const shareToken = mapper.generateShareToken.fromApi(res);

  const reporting = {
    ...campaign.reporting,
    shareToken,
  }

  dispatch(update({
    id,
    reporting,
    core: {
      updatedAt: new Date().toISOString(),
    }
  }));
}

export const uploadLogo = query => async (dispatch, getState) => {
  const {
    campaigns,
    views: { campaignDetails: { id }},
  } = getState();

  const campaign = campaigns[id];
  const oldCustomLogo = campaign.reporting.customLogo;

  const res = await api.uploadLogo({ campaignId: id, ...query });

  if (res.error) return null;

  const reporting = {
    ...campaign.reporting,
    customLogo: res?.logo,
  }

  const getUpdateAt = new Date().toISOString();

  dispatch(update({
    id,
    reporting,
    core: {
      updatedAt: getUpdateAt,
    }
  }));

  const intl = getIntl();
  toast(
    intl.formatMessage({ id: _isNil(oldCustomLogo)
      ? "campaigns.shareReporting.logoAdded"
      : "campaigns.shareReporting.logoUpdated"
    }),
    { type: 'success' }
  );
}

export const disableShareLink = () => async (dispatch, getState) => {
  const { campaigns, views: {campaignDetails: {id}} } = getState();

  const campaign = campaigns[id];

  const res = await api.disableShareLink(id);

  if (res.error) return null;

  const reporting = {
    ...campaign.reporting,
    shareToken: null,
    customLogo: null,
  }

  dispatch(update({
    id,
    reporting,
    core: {
      updatedAt: new Date().toISOString(),
    }
  }));

  const intl = getIntl();
  toast(
    intl.formatMessage(
      { id: "campaigns.shareReporting.linkDisabled" },
      { campaignName: campaign?.core?.name?.trim() }
    ),
    { type: 'success' }
  );
}

export const getCampaignUsersRoles = ({campaignId}) => async dispatch => {
  const res = await api.getCampaignUsersRoles(campaignId);
  const usersRoles = mapper.getCampaignUsersRoles.fromApi(res);

  dispatch(update({
    id: campaignId,
    usersRoles
  }))
}

export const setCampaignIsPrivate = ({
  campaignId,
  isPrivate,
  noToast,
}) => async (dispatch, getState) => {
  await api.setCampaignIsPrivate({ campaignId, isPrivate });

  dispatch(
    update({
      id: campaignId,
      core: {
        isPrivate,
        updatedAt: new Date().toISOString(),
      },
    }),
  );

  const { campaigns } = getState();
  const campaign = campaigns[campaignId];
  if (!noToast) {
    const intl = getIntl();
    toast(
      intl.formatMessage(
        { id: isPrivate ? "toasts.setCampaignPrivate" : "toasts.setCampaignShared" },
        { campaignName: campaign?.core?.name?.trim() }
      ),
      { type: 'success' }
    );
  }
};

export const setCampaignUserRole = ({
  campaignId,
  userId,
  role,
  noToast,
}) => async (dispatch, getState) => {
  await api.setCampaignUserRole({ campaignId, userId, role });

  const { campaigns } = getState();
  const campaign = campaigns[campaignId];

  dispatch(
    update({
      id: campaignId,
      usersRoles: campaign.usersRoles.map(user =>
        user.id === userId
          ? {
              ...user,
              role,
            }
          : user,
      ),
    }),
  );

  const user = campaign.usersRoles.find(u => u.id === userId);

  if (!noToast) {
    const intl = getIntl();
    toast(
      intl.formatMessage(
        { id: "toasts.userRightsCampaignChanged" },
        {
          userName: user?.fullName?.trim(),
          newRole: intl.formatMessage({ id: `campaigns.roles.${role}`}),
          campaignName: campaign?.core?.name?.trim(),
        }
      ),
      { type: 'success' }
    );
  }
};


/* SEARCH ADMIN */
export const getCampaignsByUser = ({ userId, page, perPage, searchKey }) => async () => {
  const res = await api.getCampaignsByUser({ userId, page, perPage, searchKey });
  return mapper.getCampaignsByUser.fromApi(res);
}

export const updateCampaignConfig = ({campaignId, ...query}) => async dispatch => {

  await api.updateCampaign({campaignId, ...query});

  dispatch(update({
    id: campaignId,
    admin: {
      ...query,
    }
  }));

}

export const updateFilters = (filters) => async (dispatch, getState) => {
  const { views } = getState();
  const newFilters = { ...views.campaignsList, ...filters };

  await dispatch(actionsViews.update({
    view: 'campaignsList',
    ...newFilters,
  }));
}

export const saveManualInteractionDetails = ({
  isUpdate,
  interactions,
  postId,
  isForced
}) => async (dispatch, getState) => {
  const campaignId = getState().views.campaignDetails.id;
  if (isUpdate) {
    await api.updateManualInteractions({
      campaignId,
      interactions,
      isForced,
      postId
    });
  } else {
    await api.saveManualInteractions({
      campaignId,
      interactions,
      isForced,
      postId,
    });
  }
}


/* CONTRACT - DOCUMENTS */
export const uploadKolDocuments = ({ campaignId, kolId, data }) => async dispatch => {
  if (campaignId && kolId && data) {
    dispatch(setControlledLoader({ id: 'globalLoader', show: true }));
    await api.uploadDocuments({ campaignId, kolId, data });
    dispatch(getKolDocuments({ campaignId, kolId }));
    dispatch(setControlledLoader({ id: 'globalLoader', show: false }));
  }
  return true;
}

export const deleteKolDocuments = ({ campaignId, kolId, id }) => async dispatch => {
  if (id) await api.deleteKolDocuments({ id });
  if (campaignId && kolId) dispatch(getKolDocuments({ campaignId, kolId }));
  return true;
}

export const getKolDocuments = ({ campaignId, kolId }) => async dispatch => {
  if (campaignId && kolId) {
    dispatch(setControlledLoader({ id: 'getKolDocuments', show: true }));
    const res = await api.getKolDocuments({ campaignId, kolId });
    dispatch(updateProfile({
      id: campaignId,
      profileId: kolId,
      documents: res?.contracts,
    }));
    dispatch(setControlledLoader({ id: 'getKolDocuments', show: false }));
  }
}

export const getPropositionLogs = ({ profileId, loadMore = false }) => async (dispatch, getState) => {
  dispatch(setControlledLoader({ id: 'getPropositionLogs', show: true }));

  const campaignId = getState().views.campaignDetails.id;

  if (!campaignId) return null;

  let previousLogs = [];
  let hasMore = false;
  let params = {
    page: 0,
    per_page: 10, // set as default for proposition logs, bcz space is small
  };

  if (loadMore && campaignId && profileId) {
    const { page, per_page, total_count, proposition_logs } = getState()?.campaigns?.[campaignId]?.profiles?.[profileId]?.proposition_logs;
    hasMore = total_count > proposition_logs?.length;
    previousLogs = proposition_logs;
    if (hasMore) {
      params = { page: page + 1, per_page };
    }
  }

  const response = await api.getPropositionLogs({
    campaignId,
    profileId,
    params,
  });

  await dispatch(
    updateProfile({
      id: campaignId,
      profileId,
      proposition_logs:
        loadMore && hasMore
          ? {
              ...response,
              proposition_logs: [
                ...previousLogs,
                ...response.proposition_logs,
              ],
            }
          : response,
    }),
  );

  dispatch(setControlledLoader({ id: 'getPropositionLogs', show: false }));
};

export const saveKolComment =
  ({ profileId, campaignId, comment = '' }) =>
  async (dispatch) => {
    if (!comment || !profileId || !campaignId) return null;
    try {
      dispatch(setControlledLoader({ id: 'saveKolComment', show: true }));
      const response = await api.addPropositionLog({
        campaignId,
        comment,
        profileId,
      });
      if (response?.error) {
        return Promise.reject(response.error);
      } else {
        const intl = getIntl();
        toast(intl.formatMessage({ id: 'campaigns.comments.save.success' }), {
          type: 'success',
        });
      }
      dispatch(increaseCampaignCommentCount({ profileId, campaignId }))
      return dispatch(getPropositionLogs({ profileId }));
    } catch (e) {
      return Promise.reject(e);
    } finally {
      dispatch(setControlledLoader({ id: 'saveKolComment', show: false }));
    }
  };

export const shareCastingValidation =
  ({
    user_ids = [],
    public_sharing = false,
    campaignId: _campaignId = null,
  }) =>
  async (dispatch, getState) => {
    if (user_ids.length === 0 && public_sharing === false)
      return Promise.resolve(null);

    const campaignId = _campaignId || getState().views.campaignDetails.id;
    try {
      const response = await api.shareCastingValidation({
        user_ids,
        public_sharing,
        campaignId,
      });
      if (response && !response?.error) {
        return Promise.resolve(response);
      }
    } catch (e) {
      Promise.reject(e);
    }
  };

export const saveGuestUser =
  (
    guestInfos, // type Guest
  ) =>
  async (dispatch, getState) => {
    if (!guestInfos?.email) return Promise.error('email required');
    const { castingId, id: campaignId } = getState().views.campaignDetails;
    const response = await api.saveGuestUser({
      guest: guestInfos,
      castingId,
    });
    if (!response || response.error) {
      return Promise.reject(response);
    }
    const { guest } = response;

    const cookies = new Cookies();
    cookies.set(`_kolsquare_logged_guest_${campaignId}`, guest);

    dispatch(setClientToken(guest.session_token));

    dispatch(
      actionsViews.update({
        view: 'campaignDetails',
        guest,
        loading: false,
      }),
    );
  };
