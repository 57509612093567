import _groupBy from 'lodash.groupby';
import { parseISO, isValid, min } from 'date-fns';

import { OptionalId } from 'types';
import { ContentRequestsCount } from 'types/campaigns';

import {
  ContentRequest,
  ContentRequestEdition,
} from './contentValidation.types';

export const mapContentRequestArrayToEditionMap = (
  requests: OptionalId<ContentRequest>[],
) => {
  const dataReduced = requests.reduce((acc, item) => {
    if (!acc[item.network]) {
      acc[item.network] = {};
    }
    if (!acc[item.network][item.content_type]) {
      acc[item.network][item.content_type] = [];
    }

    acc[item.network][item.content_type].push(item);

    return acc;
  }, {});
  return dataReduced;
};

export const mapEditionMapToContentRequestArray = (
  editionMap: ContentRequestEdition,
) => {
  const requests: OptionalId<ContentRequest>[] = Object.values(editionMap)
    .flatMap((byContentTypeMap) => {
      const array = Object.values(byContentTypeMap);
      return array;
    })
    .flat();
  return requests;
};

const getNextPublicationDate = (dateArray) => {
  // Filter out invalid dates and keep track of the original strings
  const validDatesWithOriginal = dateArray
    .map(date => date && isValid(parseISO(date))
      ? { original: date, parsed: parseISO(date) }
      : null
    )
    .filter(date => date !== null);

  // If there are no valid dates, return null
  if (validDatesWithOriginal.length === 0) return null;

  // Find the minimum date
  const minDateObj = min(validDatesWithOriginal.map(dateObj => dateObj.parsed));

  // Find the original string corresponding to the minimum date
  const minDateOriginal = validDatesWithOriginal.find(
    dateObj => dateObj.parsed.getTime() === minDateObj.getTime()
  ).original;

  return minDateOriginal;
}

export const mapContentRequestsCount = (requests: ContentRequest[]): ContentRequestsCount => {
  const byStatus = _groupBy(requests, 'content_status');
  const byStatusCount = Object.entries(byStatus).reduce((acc, [key, value]) => ({
    ...acc,
    [key]: value.length,
  }),{} as Record<string, number>);

  return {
    waiting_proposal: byStatusCount.waiting_proposal || 0,
    need_iteration: byStatusCount.need_iteration || 0,
    approved: byStatusCount.approved || 0,
    published: byStatusCount.published || 0,
    to_be_approved: byStatusCount.to_be_approved || 0,
    next_publication_date: getNextPublicationDate(requests.map(request => request?.expected_publication_date)),
    total: requests.length,
  };
}
