import React, { useRef, useEffect, memo } from 'react';
import { createConsumer } from '@rails/actioncable';
import { conf, DEV_DEBUG } from 'config/env';

import { ActionCableContext } from './index';

const DISABLE_WEBSOCKET = DEV_DEBUG; // disable for 8.2 and for DEV_DEBUG, remove `true || ` later

const ActionCableProvider = ({ children }) => {
  // run once, like in a constructor
  const cableRef = useRef(
    DISABLE_WEBSOCKET ? null : createConsumer(conf.websocket),
  );

  useEffect(() => {
    /* if (cable?.connection?.disconnected) {
      // TODO: handle actioncable connection failed
    } */
    return () => {
      if (cableRef.current) {
        cableRef.current.disconnect();
        cableRef.current = null;
      }
    };
  }, []);

  return (
    <ActionCableContext.Provider value={cableRef.current}>
      {children}
    </ActionCableContext.Provider>
  );
};

export default memo(ActionCableProvider);
