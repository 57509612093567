import _isEmpty from 'lodash.isempty';
import _get from 'lodash.get';
import _isEqual from 'lodash.isequal';
import moment from 'moment';

import * as profilesActions from 'actions/profiles';
import * as profileActions from 'actions/profile';
import * as viewsActions from 'actions/views';
import { showModal, setControlledLoader, delayedAction } from 'actions/ui';
import * as api from 'api/profiles';
import { launchSearchStandalone } from 'slices/search/searchEngine.slice';

import * as viewValidator from 'validators/views';
import { getProfilesIdsSortedByState } from 'selectors/campaigns';
import history from 'config/history';
import routes from 'config/routes';
import { BACKEND_DATE_FORMAT, FRONTEND_DATE_FORMAT } from 'config/app';
import { PUBLICATION_TYPES_LIST } from 'config/posts';

import { getDefaultAudienceTypeByNetwork } from 'utils/networkAudience';
import { formatDateRangeToString, getPeriodByPreset } from 'utils/dateRangeUtil';
import { formatUrl } from 'utils';
import { isValidUrl } from 'validators/sna';


const sectionAlreadyLoaded = ({ id, section, profiles }) => {
  if (!profiles[id]) return false;
  return !_isEmpty(profiles[id][section]);
}

const loadSectionStart = section => dispatch => {
  dispatch(viewsActions.updateKey({
    view: 'profileDetails',
    key: 'loaders',
    [section]: true,
  }))
}

const loadSectionComplete = section => dispatch => {
  dispatch(viewsActions.updateKey({
    view: 'profileDetails',
    key: 'loaders',
    [section]: false,
  }))
}

export const updateKey = datas => dispatch => {
  dispatch(viewsActions.updateKey({
    view: 'profileDetails',
    ...datas
  }))
}

export const update = datas => dispatch => {
  dispatch(viewsActions.update({
    view: 'profileDetails',
    ...datas
  }))
}

// Entries from ProfileDetailsDispatcher
export const hideProfileDetails = () => async dispatch => {
  dispatch(update({
    show: false,
    id: null,
    currentTab: 'profile',
  }));
}

export const backToPreviousPage = () => (dispatch, getState) => {
  // And moving
  const { goBackUrl, pageTitle } = getState().views.profileDetails.system;
  // Remove some specific search params from URL
  // Used for brand collabs, not needed on other pages
  const url = new URL(goBackUrl, window.location.origin);
  const params = new URLSearchParams(url.search);
  ['tab', 'content-search', 'period', 'sna-id'].forEach((key) =>
    params.delete(key),
  );
  url.search = params.toString().length > 0 ? `?${params.toString()}` : '';
  history.push(url.href);
  document.title = pageTitle;
}

export const showProfileDetails = ({ id, campaignId, conversationId, currentTab, monitoringFromUrl }) => async (dispatch, getState) => {
  const {
    engine: oldEngine,
    search: {
      searchEngine: newEngine,
    },
    views,
    campaigns,
    myInfluencers,
    listeningV2,
    myInfluencers: {
      filters: { listId }
    }
  } = getState();

  // INFO: Adapt profile details param depending on new or old search
  // START
  const isNewSearchEnabled = newEngine.enabled;
  let engineIds = [];
  let engineBioSearch = '';
  let engineSearch = '';
  let enginePeriod;

  if (isNewSearchEnabled) {
    engineIds = newEngine.public_profiles;
    engineBioSearch =
      (newEngine.search_scope === 'bio' || newEngine.search_scope === 'all') &&
      newEngine.terms?.length
        ? newEngine.terms
        : null;

    if (isValidUrl(newEngine.terms)) {
      engineSearch = '';
    } else if (
      newEngine.search_scope === 'content' ||
      newEngine.search_scope === 'all'
    ) {
      engineSearch = newEngine.terms;
    }
    enginePeriod = newEngine.period;
  } else {
    engineIds = oldEngine.results.rows.map(({ id }) => id);
    engineBioSearch =
      oldEngine.type === 'description' && oldEngine.filters.search?.length
        ? oldEngine.filters.search
        : null;
    engineSearch =
      oldEngine.type === 'post' && oldEngine.filters.search?.length
        ? oldEngine.filters.search.join(' ')
        : '';
    enginePeriod = oldEngine.filters.period;
  }
  // END

  const getGoBackUrlAndProfilesCarousel = () => {
    // Getting close url and profiles ids, depending on location
    // So if one day, we have to change page on next/prev arrows, we should add functions here

    if (viewValidator.isListeningPage()) {
      const monitoringIdFromStore = listeningV2.settings?.currentMonitoring?.monitoring_id;
      const dateRangesFromStore = listeningV2.settings?.dateRanges;
      const {
        monitoringId: monitoringIdFromUrl,
        startDate: startDateFromUrl,
        endDate: endDateFromUrl
      } = monitoringFromUrl;

      const monitoringId = monitoringIdFromStore || monitoringIdFromUrl;

      const startDate = dateRangesFromStore?.startDate
        ? moment(dateRangesFromStore?.startDate).format(FRONTEND_DATE_FORMAT)
        : startDateFromUrl;

      const endDate = dateRangesFromStore?.endDate
        ? moment(dateRangesFromStore?.endDate).format(FRONTEND_DATE_FORMAT)
        : endDateFromUrl;

      return {
        goBackUrl: `/${routes.listening}/${monitoringId}/${startDate}/${endDate}`,
        from: 'listening',
      }
    }

    if (viewValidator.isMyKolsManagePage()) {
      return {
        goBackUrl: listId
          ? `/${routes.myKOLs}/${routes.manage}?list=${listId}`
          : `/${routes.myKOLs}/${routes.manage}`,
        ids: myInfluencers.rows.map(({ id }) => id),
        from: 'myKols',
      };
    }

    if (viewValidator.isMyKolsFollowPage()) {
      return {
        goBackUrl: `/${routes.myKOLs}/${routes.follow}`,
        ids: myInfluencers.rows.map(({ id }) => id),
        from: 'myKols',
      };
    }

    if (viewValidator.isCampaignComparisonPage()) {
      return {
        goBackUrl: `/${routes.campaigns}/${campaignId}/${routes.comparison}`,
        ids: views.campaignDetails.compare.sortedIds,
        from: 'compare',
      }
    }

    if (viewValidator.isCampaignDiscountCodesPage()) {
      return {
        goBackUrl: `/${routes.campaigns}/${campaignId}/${routes.discountCode}`,
        from: 'discount_codes',
      }
    }

    if (viewValidator.isCampaignMessagingPage()) {
      return {
        goBackUrl: `/${routes.campaigns}/${campaignId}/${
          routes.messaging
        }/${conversationId || ''}`,
        from: 'campaign',
      };
    }

    if (viewValidator.isCampaignMonitorPage()) {
      return {
        goBackUrl: `/${routes.campaigns}/${campaignId}/${routes.reporting}`,
        ids: views.campaignDetails.reporting.profilesSorted.mainTable.ids,
        from: 'reporting',
      }
    }

    if (viewValidator.isCampaignTrackingLinksPage()) {
      return {
        goBackUrl: `/${routes.campaigns}/${campaignId}/${routes.trackingLinks}`,
        from: 'trackingLinks',
      }
    }

    if (viewValidator.isCampaignPage()) {
      const campaign = campaigns[campaignId];
      const profileState = _get(campaign?.profiles, [id, 'state'])
      const ids = campaign ? getProfilesIdsSortedByState(campaign.profiles, profileState) : [];
      return {
        goBackUrl: `/${routes.campaigns}/${campaignId}/${routes.profile}`,
        ids,
        from: 'project',
      }
    }

    if (viewValidator.isMessagingPage()) {
      return {
        goBackUrl: `/${routes.messaging}/${conversationId}`,
        from: 'messaging',
      };
    }
    // Content search
    if (viewValidator.isContentSearchPage()) {
      return {
        goBackUrl: `/${routes.contentSearch}`,
        from: 'content-search',
      };
    }

    // else, we should be on engine page
    return {
      goBackUrl: formatUrl(`/`),
      ids: engineIds,
      from: 'engine',
    }
  }

  dispatch(update({
    show: true,
    id,
    bioSearch: engineBioSearch,
    system: {
      ...getGoBackUrlAndProfilesCarousel(),
      pageTitle: document.title,
    },
    currentTab
  }));

  dispatch(updateKey(Object.assign({},
    {
      key: 'contentFilters',
      page: 0,
      types: PUBLICATION_TYPES_LIST,
      selectedSnas: [],
    },
    !viewValidator.isListeningPage() && {
      search: engineSearch,
      date: {
        slice : enginePeriod,
      },
    })
  ));
}
// ---

// Profile Sheet datas entry point
export const loadProfileDetails = kolId => async (dispatch, getState) => {
  const { id, performanceFilters, currentTab } = getState().views.profileDetails;
  const { features: { manageCampaigns } } = getState().user;

  if (!kolId || !id) return null;

  const targetId = !id ? kolId : id;

  dispatch(loadSectionStart('core'));

  let profile;

  await dispatch(profilesActions.loadProfileCoreAndAudience(targetId)).then(
    (loadedProfile) => {
      profile = loadedProfile;
    },
    (e) => {
      dispatch(loadSectionComplete('core'));
      throw e;
    },
  );
  // Updating PerformanceFilter default SnaId

  // get the highest communityCount with audience datas
  const audienceSnaId = profile.sortedSnas.byCommunityCount.find(
    snaId =>
      profile.audience.snas?.[snaId].followers.ages?.length ||
      profile.audience.snas?.[snaId].followers.locations?.length ||
      profile.audience.snas?.[snaId].followers.interests?.length ||
      profile.audience.snas?.[snaId].followers.languages?.length,
  );

  // get the highest communityCount with/without audience datas
  const statsSnaId = profile?.sortedSnas?.byCommunityCount?.[0];

  // if the highest communityCount does not exist => we disable 'stats' tab => redirect to 'profile' tab
  if (!statsSnaId && currentTab === 'stats') dispatch(updateTab('profile'));

  dispatch(updateKey({
    key: 'performanceFilters',
    ...performanceFilters,
    snaId: statsSnaId || null
  }));

  // For audience tab, must have audience data
  dispatch(selectAudienceSna(audienceSnaId));

  if (manageCampaigns) {
    dispatch(profilesActions.loadProfileCampaigns(targetId))
  }

  dispatch(profilesActions.loadProfileLists(targetId))

  dispatch(loadSectionComplete('core'));

  return profile;
}

// ProfileDetails PanelWrapper should load...
export const loadProfileTabProfile = () => async dispatch => {
  dispatch(loadProfileAbout());
  dispatch(loadProfileTopMentions());
}

export const loadProfileTabContent = () => (dispatch, getState) => {
  const { views: { profileDetails: { contentFilters: { search } } } } = getState();

  dispatch(updateContentSearch(search))
  dispatch(loadProfileTopHashtags());
}
// ---


export const loadProfileAbout = () => async (dispatch, getState) => {
  const { views: { profileDetails: { id, bioSearch } }, profiles } = getState();

  if (sectionAlreadyLoaded({
    id,
    section: 'about',
    profiles
  })) return null;

  dispatch(loadSectionStart("about"));

  await dispatch(profilesActions.loadProfileAbout({
    id,
    search: bioSearch,
  }));

  dispatch(loadSectionComplete("about"));
}

export const loadProfileStats = () => async (dispatch, getState) => {
  const { views: { profileDetails: { id } }, profiles } = getState();

  const [snaId] = Object.keys(profiles?.[id].snas);

  if (_get(profiles, `${id}.snas.${snaId}.stats.emv`)) return null;   // EMV, which is load only from here, appears to be already loaded, so we won't have to load it anymore

  dispatch(loadSectionStart('stats'));

  await dispatch(profilesActions.loadProfileStats(id));

  dispatch(loadSectionComplete('stats'));
}

export const loadProfileTopHashtags = () => async (dispatch, getState) => {
  const { views: { profileDetails: { id }}, profiles } = getState();

  if (sectionAlreadyLoaded({
    id,
    section: 'topHashtags',
    profiles
  })) return null;

  dispatch(loadSectionStart("topHashtags"));

  dispatch(profilesActions.loadProfileTopHashtags(id));

  dispatch(loadSectionComplete("topHashtags"));
}

export const loadProfilePosts = () => async (dispatch, getState) => {
  const { id, contentFilters } = getState().views.profileDetails;

  dispatch(loadSectionStart('content'));

  const response = await dispatch(
    launchSearchStandalone({
      profile_ids: [Number(id)],
      content_types: contentFilters.types,
      researchable_element: 'content',
      terms: contentFilters.search || '',
      search_scope: 'content',
      sort: contentFilters.sortBy,
      required_networks: contentFilters.selectedSnas || [],
      from: contentFilters.date.from,
      to: contentFilters.date.to,
      page: contentFilters.page || 0,
      per_page: 12,
      global_statistics:
        !!contentFilters.search && contentFilters.search.trim().length > 0, // to load stats
      // Not needed for content search, set to default
      profile_type: 'all', // Account type
      network_filters_operator: 'or',
      basic_filters: [],
      profile_genders: [],
      excluded_project_ids: [],
      excluded_list_ids: [],
      excluded_tag_ids: [],
      exclude_favorites: false,
      profile_filters: {},
      instagram_filters: {},
      youtube_filters: {},
      tiktok_filters: {},
      twitter_filters: {},
      facebook_filters: {},
      snapchat_filters: {},
    }),
  );

  dispatch(
    profilesActions.update({
      id,
      content: {
        posts: response.payload?.contents || [],
        total: response.payload?.total_count || 0,
      },
    }),
  );

  dispatch(viewsActions.update({
    view: 'profileDetails',
    statistics_by_account: response.payload?.statistics_by_account || null,
  }))

  dispatch(loadSectionComplete('content'));
}

/** *******
 *
 * MANAGE PROFILE DETAILS
 */

export const updateProfileAddressDetails = (id, addressDetails) => async dispatch => {
  dispatch(loadSectionStart('addressDetails'));
  await dispatch(
    profilesActions.updateProfileDetails(id, addressDetails),
  );
  dispatch(loadSectionComplete('addressDetails'));
};

export const resetProfileAddressDetails = (id, addressDetails) => async dispatch => {
  dispatch(loadSectionStart('addressDetails'));
  await dispatch(
    profilesActions.updateProfileDetails(id, addressDetails),
  );
  dispatch(loadSectionComplete('addressDetails'));
};

export const loadProfileAgentDetails = id => async dispatch => {
  dispatch(loadSectionStart('agentDetails'));
  try {
    await dispatch(profilesActions.loadProfileDetails(id));
  } catch (e) {
    console.error('[loadProfileAgentDetails] ->', e);
  }
  dispatch(loadSectionComplete('agentDetails'));
};

export const updateProfileAgentDetails = (
  id,
  agentDetails,
) => async dispatch => {
  dispatch(loadSectionStart('agentDetails'));
  await dispatch(profilesActions.updateProfileDetails(id, agentDetails));
  dispatch(loadSectionComplete('agentDetails'));
};

/* CONTENT */

export const updateContentPage = page => dispatch => {
  dispatch(updateKey({
    key: 'contentFilters',
    page,
  }));

  dispatch(profileActions.loadProfilePosts());
}

export const updateContentSearch = search => (dispatch, getState) => {
  const { views: { profileDetails: { contentFilters } } } = getState();

  console.log('mamamama')

  const params = Object.assign(
    {
      search,
      page: 0
    },
    (search?.length === 0) && (contentFilters.sortBy === 'relevance')
      ? { sortBy: 'latest_post' }
      : null
  );

  dispatch(updateKey({
    key: 'contentFilters',
    ...params,
  }));

  dispatch(profileActions.loadProfilePosts());
}

export const updateContentSelectedSnas = selectedSnas => dispatch => {

  dispatch(updateKey({
    key: 'contentFilters',
    selectedSnas,
    page: 0,
  }));
  dispatch(profileActions.loadProfilePosts());
}

export const updateContentDate = ({
  slice,
  startDate,
  endDate,
}) => dispatch => {
  const value = slice || formatDateRangeToString({ startDate, endDate });
  const { start_at: from, end_at: to } = getPeriodByPreset(
    value,
    BACKEND_DATE_FORMAT,
    FRONTEND_DATE_FORMAT,
  );

  console.log('zollleee')

  dispatch(
    updateKey({
      key: 'contentFilters',
      date: {
        slice: value,
        from,
        to,
      },
      page: 0,
    }),
  );
  dispatch(profileActions.loadProfilePosts());
};

export const updateContentPublicationTypes = types => dispatch => {
  dispatch(updateKey({
    key: 'contentFilters',
    types,
    page: 0,
  }));
  dispatch(profileActions.loadProfilePosts());
}

export const updateContentSortBy = sortBy => dispatch => {
  dispatch(updateKey({
    key: 'contentFilters',
    sortBy,
    page: 0,
  }));
  dispatch(profileActions.loadProfilePosts());
}


/* AUDIENCE */
export const updateAudienceType = type => dispatch => {
  dispatch(updateKey({
    key: 'audience',
    show: type,
  }));
}

/* TAB */
export const updateTab = tab => dispatch => {
  dispatch(update({
    currentTab: tab,
  }));
}

export const switchAudienceType = audienceType => dispatch => {
  dispatch(viewsActions.updateKey({
    view: 'profileDetails',
    key: 'audience',
    display: audienceType,
  }))
}

export const selectAudienceSna = snaId => (dispatch, getState) => {
  const { profiles, views } = getState();

  if (!snaId) return null;

  const network = profiles?.[views?.profileDetails?.id]?.snas?.[snaId]?.network;

  dispatch(
    viewsActions.updateKey({
      view: 'profileDetails',
      key: 'audience',
      snaId,
      display: getDefaultAudienceTypeByNetwork(network),
    }),
  );
}

/* TOP MENTIONS */
let previousMentionsSearchedQuery = {};
export const loadProfileTopMentions = () => async (dispatch, getState) => {
  const { views: { profileDetails: { id, topMentionsFilters: filters }} } = getState();

  const datas = {
    id,
    filters,
  };

  // Do not perform new search if we have not changed filters or id
  if (_isEqual(previousMentionsSearchedQuery, datas)) return null;

  previousMentionsSearchedQuery = datas;

  dispatch(loadSectionStart("topMentions"));

  await dispatch(profilesActions.getTopMentions(datas));

  dispatch(loadSectionComplete("topMentions"));
}

export const updateTopMentionsFilters = ({ key, value }) => dispatch => {
  dispatch(updateKey({
    key: 'topMentionsFilters',
    [key]: value,
  }));

  dispatch(viewsActions.updateKey({
    view: 'profileDetails',
    key: 'topMentionsFilters',
    ...value
  }))

  delayedAction(loadProfileTopMentions(), 300)
}

export const openReportProfileModal = () => (dispatch, getState) => {
  const { views: {profileDetails:{id}} } = getState();

  dispatch(showModal({
    id: 'reportProfile',
    data: {
      profileId: id,
    }
  }));
}

export const reportProfile = data => async dispatch => {
  dispatch(setControlledLoader({ id: 'reportProfile', show: true }));
  await api.reportProfile(data)
  dispatch(setControlledLoader({ id: 'reportProfile', show: false }));
}

export const reportTheme = data => async dispatch => {
  dispatch(setControlledLoader({ id: 'reportTheme', show: true }));
  await api.reportThemes(data)
  dispatch(setControlledLoader({ id: 'reportTheme', show: false }));
}

export const reloadProfileIfOpened = ({ profileIds }) => async (dispatch, getState) => {
  const openedProfileId = getState().views?.profileDetails?.id;
  if (
    profileIds?.length > 0 &&
    openedProfileId &&
    profileIds.includes(Number(openedProfileId))
  ) {
    dispatch(profilesActions.loadProfileCore(openedProfileId));
  }
};
