import { useCallback } from 'react';

import { hideModal, showModal, updateModal } from 'actions/ui';
import { updateProfileDetailsId } from 'actions/views';
import { useDispatch, useSelector } from 'utils/redux';

const useModal = <T extends Record<string, unknown>>(
  id: string,
): {
  on: (data?: T) => void;
  off: () => void;
  /** Hide modal but do not clear data */
  hide: () => void;
  update: (data?: T) => void;
  show: boolean;
  data: T;
} => {
  const dispatch = useDispatch();

  const { show, data } = useSelector(({ ui: { modales } }) => {
    const modale = modales[id] || {
      show: false,
      data: {}
    };
    return {
      show: Boolean(modale?.show),
      data: modale?.data as T,
    };
  });

  const on = useCallback(
    data => {
      dispatch(showModal({ id, data: data || {} }));
      if (data?.profileId) {
        dispatch(updateProfileDetailsId(data.profileId));
      }
    },
    [dispatch, id],
  );

  const off = useCallback(
    () => dispatch(hideModal({ id, data: {} })),
    [dispatch, id]
  );

  const hide = useCallback(
    () =>
      dispatch(
        updateModal({
          id,
          datas: { show: false, data },
        }),
      ),
    [dispatch, id, data],
  );

  const update = useCallback(
    newData =>
      dispatch(updateModal({
        id,
        datas: { data: { ...data, ...newData } }
      })),
    [dispatch, id, data],
  );

  return {
    on,
    off,
    hide,
    update,
    show,
    data,
  };
};

export default useModal;
