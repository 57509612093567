import { get, post, put } from 'api';
import { ApiPromise } from 'api/types';
import { OptionalId } from 'types';

import {
  ContentProposalVersion,
  ContentRequest,
  AggregatedContentRequest,
} from './contentValidation.types';

export const CHUNK_UPLOAD_API = (projectId) =>
  `/influence/v2/projects/${projectId}/content_proposals/upload`;

export const getContentRequests: (params: {
  profile_id: number;
  project_id: number;
}) => ApiPromise<{
  content_requests: Array<ContentRequest>;
  aggregated_content_requests: AggregatedContentRequest;
}> = ({ project_id, profile_id }) =>
  get({
    endpoint: `influence/v2/projects/${project_id}/content_requests`,
    catchErrors: [404],
    params: {
      public_profile_id: profile_id,
      scopes: 'contents',
    },
  });

export const getContentProposals: (params: {
  project_id: number;
  profile_id: number;
}) => ApiPromise<{
  content_proposals: Array<ContentProposalVersion>;
}> = ({ profile_id, project_id }) =>
  get({
    endpoint: `influence/v2/projects/${project_id}/content_proposals`,
    catchErrors: [404],
    params: {
      public_profile_id: profile_id,
    },
  });

export const uploadContentVersion: (params: {
  profile_id: number;
  project_id: number;
  content_request_id: number;
  caption: string;
  upload_ids: Array<string>;
}) => ApiPromise<ContentProposalVersion> = async ({
  project_id,
  profile_id,
  content_request_id,
  caption,
  upload_ids,
}) => {
  const response: ContentProposalVersion = await post({
    endpoint: `influence/v2/projects/${project_id}/content_proposals`,
    catchErrors: [404],
    params: {
      content_proposal: {
        content_request_id,
        caption,
        upload_ids,
        public_profile_id: profile_id,
      },
    },
  });
  return {
    ...response,
  };
};

export const updateContentRequests: (params: {
  profile_ids: number[];
  project_id: number;
  content_requests: Array<OptionalId<ContentRequest>>;
}) => ApiPromise<{
  content_requests: ContentRequest[];
  aggregated_content_requests: AggregatedContentRequest;
}> = ({ project_id, profile_ids, content_requests }) => {
  if (profile_ids?.length === 1) {
    return post({
      endpoint: `influence/v2/projects/${project_id}/content_requests/update_requests`,
      catchErrors: [404],
      params: {
        public_profile_id: profile_ids[0],
        content_requests,
      },
    });
  } else {
    return post({
      endpoint: `influence/v2/projects/${project_id}/content_requests/batch_update`,
      catchErrors: [404],
      params: {
        public_profile_ids: profile_ids,
        content_requests,
      },
    });
  }
};

export const updateContentRequestStatus: (params: {
  project_id: number;
  requestId: number;
  content_status: string;
}) => ApiPromise<any> = async ({ project_id, requestId, content_status }) => {
  return put({
    endpoint: `influence/v2/projects/${project_id}/content_requests/${requestId}`,
    params: {
      content_status,
    },
  });
};

export const updateContentInstructions: (params: {
  project_id: number;
  profile_id: number;
  content_instructions: string;
}) => ApiPromise<{
  public_profile_id: number;
  content_instructions: string;
}> = ({ project_id, profile_id, content_instructions }) =>
  post({
    endpoint: `influence/projects/${project_id}/profiles/${profile_id}/update_instructions`,
    catchErrors: [404],
    params: {
      public_profile_id: profile_id,
      content_instructions: content_instructions || '',
    },
  });
