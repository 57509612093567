import { actions } from './campaignWorkflow.reducers';

// Types
export type {
  Workflow,
  Status,
  SliceState,
  WorkflowEdition,
} from './campaignWorkflow.types';

// Actions
export const {
  updateKey,
  addNewStatus,
  updateStatuses,
  updateCurrentWorkflowPropositionCount,
  updateProfilesDeletedCount,
} = actions;

export {
  // Thunk
  getCampaignWorkflows,
  addNewWorkflow,
  updateWorkflow,
  removeWorkflow,
  addStatus,
  deleteStatus,
  updateCampaignWorkflow,
  importWorkflowFromCampaign,
  updateCampaignWorkflowSelection,
} from './campaignWorkflow.actions';

export { default } from './campaignWorkflow.reducers';
