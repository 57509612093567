import _orderBy from 'lodash.orderby';
import _groupBy from 'lodash.groupby';
import type { PublicProfile } from 'slices/search/searchEngine.slice/searchEngine.types';
// Profile type in redux store
import { Profile } from 'types/profiles';

import { networks } from 'config/filters';

export const mapNewProfileToOldOne = (profile: PublicProfile): Profile => {
  const getFullName = () => {
    const { first_name, last_name, full_name } = profile.core;
    if (full_name) return full_name;
    if (first_name) {
      if (last_name) return `${first_name} ${last_name}`;
      return first_name;
    }

    if (last_name) return last_name;

    return '';
  };

  const arrayOfSna = Object.values(profile.snas || {});

  const core: Profile['core'] = {
    pseudo: profile.core.pseudo,
    enlisted: profile.core.influence_for_good,
    fullName: getFullName(),
    firstName: profile.core.first_name || '',
    lastName: profile.core.last_name || '',
    gender: profile.core.gender,
    isFavorite: profile.core.is_favorite,
    themes: profile.core.themes,
    activities: profile.core.activities,
    picture: profile.core.picture_url,
    picture_fallback: undefined,
    isContactable: profile.core.is_contactable,
    isInactive: profile.core.is_inactive || false,
    contactableEmail: profile.core.contactable_email,
    hasCommunityAccess: false,
    hasNote: profile.core.has_note,
    qualityScore: profile.core.audience_credibility_score,
    totalCommunityCount: arrayOfSna.reduce(
      (total, sna) => total + (sna.community_count || 0),
      0,
    ),
    type: 'profile',
    owners: [],
    primarySnaId: null,
    isStatsSharingActive: profile.core.is_stat_sharing_active,
    arpp_certified: profile.core.arpp_certified,
    arpp: profile.core.arpp_certified,
  };

  const snas: Profile['snas'] = arrayOfSna.reduce(
    (reduced, sna) => ({
      ...reduced,
      [sna.id]: {
        id: sna.id,
        uid: sna.id,
        username: sna.username,
        isBlocked: sna.spammer,
        isPrivate: sna.concealed,
        isInactive: sna.inactive,
        isVerified: false,
        network: sna.label,
        stats: {
          id: sna.id,
          communityCount: sna.community_count,
        },
        pseudo: sna.username,
        url: '',
        biography: '',
        audience_credibility_score: sna.audience_credibility_score,
      },
    }),
    {},
  );

  const groupedSnas = _groupBy(profile.snas, 'label');
  return {
    id: profile.core.id,
    core,
    audience: {},
    about: {},
    topHashtags: [],
    content: {},
    note: {},
    tags: [],
    history: {},
    snas,
    statsSharing: { status: '' },
    snasWithBio: Object.values(snas)
      .filter((sna) => sna.biography)
      .map((sna) => sna.id),
    top: {
      countries: [],
      ages: [],
      interests: [],
      genders: [],
      reachability: [],
    },
    campaigns: {
      loaded: false,
      ids: [],
    },
    contactableEmail: core.contactableEmail || '',
    lastContactId: null,
    contact: [],
    details: {
      affilaeId: null,
      pseudo: core.pseudo || null,
      firstName: core.firstName || null,
      lastName: core.lastName || null,
      email: core.contactableEmail || '',
      phoneNumber: null,
      address: null,
      agentName: null,
      agentEmail: '',
      agentPhoneNumber: null,
      projectsInvestedBudget: 0,
      investedBudget: null,
      zipCode: null,
      lists: [],
      fields: {},
    },
    lists: [],
    fields: {},
    availableNetworks: Array.from(new Set(arrayOfSna.map((sna) => sna.label))),
    sortedSnas: {
      byCommunityCount: arrayOfSna
        .sort((sna1, sna2) => sna2.community_count - sna1.community_count)
        .map((sna) => sna.id),
      bycommitmentPerPost: arrayOfSna
        .sort(
          (sna1, sna2) =>
            sna2.avg_engagement_rate_per_post -
            sna1.avg_engagement_rate_per_post,
        )
        .map((sna) => sna.id),
      byNetworks: networks.reduce((p, key) => {
        const snasByNetwork = groupedSnas[key] || null;
        return Object.assign(p, {
          [key]: snasByNetwork
            ? {
                ids: snasByNetwork.map((j) => j.id),
                communityCount: _orderBy(
                  snasByNetwork,
                  ['community_count'],
                  ['desc'],
                ).map((j) => j.id),
                commitmentPerPost: _orderBy(
                  snasByNetwork,
                  ['avg_engagement_per_post_absolute'],
                  ['desc'],
                ).map((j) => j.id),
                viewsPerPost: _orderBy(
                  snasByNetwork,
                  ['avg_views_count'],
                  ['desc'],
                ).map((j) => j.id),
                commitmentPerMediaPercent: _orderBy(
                  snasByNetwork,
                  ['avg_engagement_per_post_percent'],
                  ['desc'],
                ).map((j) => j.id),
              }
            : {
                ids: [],
                communityCount: [],
                viewsPerPost: [],
                commitmentPerPost: [],
                commitmentPerMediaPercent: [],
              },
        });
      }, {} as NonNullable<Profile['sortedSnas']>['byNetworks']),
    },
    affilae: {},
    topMentions: [],
  };
};
