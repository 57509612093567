import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from 'config/store';

import toast from 'utils/toast';
import { getIntl } from 'utils/HOCs/IntlGlobalSingleton';

import { OptionalId } from 'types';

import { updateProfile, updateProfiles, updateProfileState } from 'actions/campaigns';
import { extractKolName } from 'utils';
import * as api from './contentValidation.api';
import {
  ContentProposalVersion,
  ContentRequest,
  AggregatedContentRequest,
} from './contentValidation.types';
import {
  mapEditionMapToContentRequestArray,
  mapContentRequestsCount,
} from './contentValidation.utils';

export const getContentRequests = createAsyncThunk<
  {
    content_requests: Array<ContentRequest> | null;
    aggregated_content_requests: AggregatedContentRequest;
    project_id: number;
    profile_id: number;
  },
  { project_id: number; profile_id: number },
  { state: RootState }
>(
  'campaign/content_validation/getContentRequests',
  async ({ project_id, profile_id }) => {
    const response = await api.getContentRequests({
      project_id,
      profile_id,
    });
    if (!response?.error) {
      return Promise.resolve({
        project_id,
        profile_id,
        content_requests: response.content_requests,
        aggregated_content_requests: response.aggregated_content_requests,
      });
    } else {
      return Promise.reject(response.error);
    }
  },
);

export const updateContentRequests = createAsyncThunk<
  {
    project_id: number;
    profile_ids: number[];
    content_requests: Array<OptionalId<ContentRequest>>;
    aggregated_content_requests: AggregatedContentRequest;
  },
  {
    project_id: number;
    profile_ids: number[];
  },
  { state: RootState }
>(
  'campaign/content_validation/updateContentRequests',
  async ({ project_id, profile_ids }, { dispatch, getState }) => {
    const { campaigns, contentValidation } = getState();
    const content_requests: Array<OptionalId<ContentRequest>> =
      mapEditionMapToContentRequestArray(
        contentValidation.contentRequestsToEdit,
      );

    const response = await api.updateContentRequests({
      project_id,
      profile_ids,
      content_requests,
    });
    if (!response?.error) {
      const profilesToUpdated = profile_ids.map((id) => {
        const profile = campaigns?.[project_id]?.profiles?.[id];
        return {
          ...profile,
          content_requests: mapContentRequestsCount(response?.content_requests),
        };
      });
      dispatch(
        updateProfiles({
          id: project_id,
          profiles: profilesToUpdated,
        }),
      );

      return Promise.resolve({
        project_id,
        profile_ids,
        content_requests: response?.content_requests,
        aggregated_content_requests: response?.aggregated_content_requests,
      });
    } else {
      return Promise.reject(response.error);
    }
  },
);

export const updateContentRequestStatus = createAsyncThunk<
  {
    project_id: number;
    profile_id: number;
    requestId: number;
    content_status: ContentRequest['content_status'];
  },
  {
    project_id: number;
    profile_id: number;
    requestId: number;
    oldStatus: ContentRequest['content_status'];
    newStatus: ContentRequest['content_status'];
  },
  { state: RootState }
>(
  'campaign/content_validation/updateContentRequestStatus',
  async (
    { project_id, profile_id, requestId, oldStatus, newStatus },
    { dispatch, getState },
  ) => {
    if (oldStatus === newStatus) {
      return Promise.resolve({
        project_id,
        profile_id,
        requestId,
        content_status: newStatus,
      });
    }

    const response = await api.updateContentRequestStatus({
      project_id,
      requestId,
      content_status: newStatus,
    });
    if (!response?.error) {
      const targetProfile =
        getState().campaigns?.[project_id]?.profiles?.[profile_id];
      // Update content requests columns values
      dispatch(
        updateProfile({
          id: project_id,
          profileId: profile_id,
          content_requests: {
            ...targetProfile.content_requests,
            [newStatus]: (targetProfile.content_requests?.[newStatus] || 0) + 1,
            [oldStatus]: (targetProfile.content_requests?.[oldStatus] || 1) - 1,
          },
        }),
      );

      toast(
        getIntl().formatMessage(
          { id: 'toasts.contentValidation.status.updated' },
          {
            oldState: getIntl().formatMessage({
              id: `campaigns.contentValidation.state.${oldStatus}`,
            }),
            newState: getIntl().formatMessage({
              id: `campaigns.contentValidation.state.${newStatus}`,
            }),
          },
        ),
        { type: 'success' },
      );

      // Move KOL to "Published" state if all content are published
      const isAllContentPublished =
        getState().contentValidation.contentRequestsByCampaign[project_id]?.[
          profile_id
        ]?.every((request) => {
          if (request.id === requestId) {
            return newStatus === 'published';
          } else {
            return request.content_status === 'published';
          }
        });
      if (isAllContentPublished) {
        const { current_workflow_id } = getState().campaignsWorkflow;
        if (current_workflow_id) {
          const teamStatuses = getState().campaignsWorkflow.team_statuses;
          const publishedStatusId = getState().campaignsWorkflow.workflows[
            current_workflow_id
          ]?.status_ids.find(
            (statusId) =>
              teamStatuses[statusId]?.is_default &&
              teamStatuses[statusId]?.key === 'published',
          );
          if (publishedStatusId) {
            void dispatch(
              updateProfileState({
                profileId: profile_id,
                profilesId: [],
                campaignId: project_id,
                stateId: publishedStatusId,
                fromConversation: false,
              }),
            );
          }
        }
      }

      return Promise.resolve({
        project_id,
        profile_id,
        requestId,
        content_status: response?.content_status,
      });
    } else {
      return Promise.reject(response.error);
    }
  },
);

/**
 * Update content instructions For KOL in a campaign
 */
export const updateContentInstructions = createAsyncThunk<
  {
    project_id: number;
    profile_id: number;
    content_instructions: string;
  },
  { project_id: number; profile_id: number; content_instructions: string },
  { state: RootState }
>(
  'campaign/content_validation/updateContentInstructions',
  async ({ project_id, profile_id, content_instructions }, { dispatch, getState }) => {
    const response = await api.updateContentInstructions({
      project_id,
      profile_id,
      content_instructions,
    });
    if (!response?.error) {
      dispatch(
        updateProfile({
          id: project_id,
          profileId: profile_id,
          content_instructions,
        }),
      );

      const profileData = getState().profiles?.[profile_id];
      toast(
        getIntl().formatMessage(
          { id: 'toasts.contentValidation.instructions.updated' },
          { kolName: extractKolName(profileData) },
        ),
        { type: 'success' },
      );

      return Promise.resolve({
        project_id,
        profile_id,
        content_instructions,
      });
    } else {
      return Promise.reject(response.error);
    }
  },
);

export const getContentProposals = createAsyncThunk<
  { content_proposals: Array<ContentProposalVersion> },
  { profile_id: number; project_id: number },
  { state: RootState }
>(
  'campaign/content_validation/getContentProposals',
  async ({ profile_id, project_id }) => {
    const response = await api.getContentProposals({
      profile_id,
      project_id,
    });
    if (!response?.error) {
      return Promise.resolve({
        content_proposals: response.content_proposals,
      });
    } else {
      return Promise.reject(response.error);
    }
  },
);

export const uploadContentVersion = createAsyncThunk<
  {
    contentProposal: ContentProposalVersion;
  },
  {
    profile_id: number;
    project_id: number;
    content_request_id: number;
    caption: string;
    upload_ids: Array<string>;
  },
  { state: RootState }
>(
  'campaign/content_validation/uploadContentVersion',
  async (
    { profile_id, project_id, content_request_id, caption, upload_ids },
    { getState }
  ) => {
    const response = await api.uploadContentVersion({
      profile_id,
      project_id,
      content_request_id,
      caption,
      upload_ids,
    });
    if (!response?.error) {
      const contentRequests = getState()?.contentValidation?.contentRequestsByCampaign?.[project_id]?.[profile_id];
      const contentRequestData = contentRequests?.find(({ id }) => id === content_request_id);
      toast(
        getIntl().formatMessage(
          { id: 'toasts.contentValidation.newVersion.added' },
          {
            contentType: getIntl().formatMessage({ id: `global.content.${contentRequestData?.content_type}` }),
            network: contentRequestData?.network,
          }
        ),
        { type: 'success' },
      );

      return Promise.resolve({
        contentProposal: response,
      });
    } else {
      return Promise.reject(response.error);
    }
  },
);
