import {
  LicenceDetails,
  Member,
  MemberForm,
  Team,
  TeamAccess,
  TeamMember,
} from 'slices/organization.slice';
import { PaginatedResponse } from 'types';

import { get, post, put } from './index';
import { ApiPromise } from './types.d';

export const mapMemberInfos = (member: MemberForm) => {
  const { email, first_name, last_name, phone, position_title } = member;
  return {
    user: {
      email,
    },
    contact: { first_name, last_name, phone, position_title },
  };
};

// Members
export const fetchMembers: () => ApiPromise<Member[]> = () => {
  return get({
    endpoint: `influence/companies/members`,
    catchErrors: [],
    catchNoAnswer: true,
  });
};

export const createMember: (
  member: MemberForm,
) => ApiPromise<{ id: number }> = member => {
  return post({
    endpoint: `influence/companies/members`,
    params: mapMemberInfos(member),
    catchErrors: [422],
    catchNoAnswer: true,
  });
};

export const updateMember: (
  member: MemberForm,
) => ApiPromise<{ id: number }> = member => {
  return put({
    endpoint: `influence/companies/members/${member.id}`,
    params: mapMemberInfos(member),
    catchErrors: [422],
    catchNoAnswer: true,
  });
};

// Teams
export const fetchTeams: () => ApiPromise<Team[]> = () => {
  return get({
    endpoint: `influence/companies/teams`,
    catchErrors: [],
  });
};
export const saveTeamRoleAndAccess = ({ accountId, teamId, role, teamsAccess}) => {
  return put({
    endpoint: `influence/companies/team/team_members`,
    params: {
      team_members: {
        contact_id: accountId,
        team_id: teamId,
        role,
        managed_team_ids: teamsAccess
      }
    },
    catchNoAnswer: true,
  });
};

export const saveTeam: (team: Partial<Team>) => ApiPromise<Team> = team => {
  const endpoint = team.id
    ? `influence/companies/teams/${team.id}`
    : 'influence/companies/teams';

  return (team.id ? put : post)({
    endpoint,
    params: { name: team.name?.trim() },
    catchErrors: [422],
  });
};

export const fetchTeamsAccess: () => ApiPromise<TeamAccess[]> = () => {
  return get({
    endpoint: `influence/companies/teams/team_access`,
    catchErrors: [],
  });
};

export const fetchTeamsMembers: (
  params: Partial<{ page?: number; per_page?: number; search?: string }>,
) => ApiPromise<
  PaginatedResponse<{
    users: TeamMember[];
  }>
> = (params) => {
  return get({
    endpoint: `influence/v2/team_members`,
    params,
    catchErrors: [],
    catchNoAnswer: true,
  });
};

// Licences
export const fetchLicences: () => ApiPromise<LicenceDetails[]> = () => {
  return get({
    endpoint: `influence/companies/licences`,
    catchErrors: [],
  });
};

export const saveCompanyLicences: (params: {
  licences: { id: number; contact_id: number | null }[];
}) => ApiPromise<void> = ({ licences }) => {
  const endpoint = `influence/companies/licences/batch`;
  return post({ endpoint, params: { licences } });
};
